export const data = { 

  logo: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FAUTHORISED%20CHANNEL%20PARTNER%20(2).png02e5e05b-9b58-4383-9c1a-0a8a06d18eda?alt=media&token=22efd39c-b3e4-42d6-a35a-c83c6760ba09", 

  projectName: "Silverglades Legacy - Google Ads", 

  //heroSection 

  heroSection: { 

mobileImages: [
  
  "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%20mobile%201.webpe0c38994-c7a8-4313-bbec-b126f2e96e06?alt=media&token=51638203-030f-4c97-ae81-a69bfa90b36c", 
  
  "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%20mobile%202.webp1ae8899d-74d2-4b1f-abf4-e362cc0057c7?alt=media&token=30953c36-1881-4744-a364-cfd9fc6b5ea8",

], 

    desktopImages: [ 

      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%20desktop%201.webpc3eda102-f9de-44e1-b6c8-895d2e63a864?alt=media&token=ac411b2c-72aa-4dd2-9586-1c4bc31e6460", 

      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%20desktop%202.webp54eeff28-edce-4e22-a992-1aea01972160?alt=media&token=582499b3-5ea3-4900-b055-566097b62e02", 

    ], 

    heading: "Overview", 

    addressHeading: "Silverglades The Legacy", 

    addressSubHeading: 

      "At Sector 63A, Gurgaon By Silverglades", 

    description: 

      "Silverglades The Legacy is an upcoming luxury residential project by Silverglades Group, located in Sector 63A, Gurgaon, along Golf Course Extension Road. Spanning 10.5 acres, this exclusive development features 5 high-rise towers with 410 spacious 3, 4, and 5 BHK apartments and penthouses, ranging from 3,000 to 4,000 sq. ft. Designed for those seeking an aristocratic lifestyle, Silverglades Legacy offers a unique blend of luxury and comfort with world-class amenities, including a swimming pool, gym, and clubhouse. The project is set in a reputed neighborhood, providing a peaceful and secure environment, with easy access to major amenities such as supermarkets, schools, and hospitals. <br> </br> This newly launched property promises high returns on investment and a grand living experience, combining a serene atmosphere with the vibrancy of Gurgaon’s urban landscape. Silverglades Legacy is poised to become a landmark in luxurious living, offering residents a perfect blend of comfort, security, and convenience.", 

    button: "Download Brochure", 

    popupData: { 

      heading: "Download Brochure ", 

      button: "Download Brochure", 

    }, 

  }, 

  //card data 

 

  cardData: { 

    popupData: { 

      heading: "Enquiry ", 

      button: "Enquiry", 

    }, 

 

    heading: "Book Now, RERA Approved Project", 

    mainHeading: "3, 4, 5 BHK Luxury Homes!", 

    address: "Silverglades The Legacy", 

    subAddress: "At Sector 63A, Gurgaon", 

    fields: [ 

      { key: "10.5 Acres", label: "Land Area" }, 

      { key: "2029", label: "Possession" }, 

      { key: "G+35", label: "Floors" }, 

    ], 

    buttonData: { 

      discount: "Newly Launched!", 

      plantTime: "Luxury 3, 4 BHK Apartments & 5 BHK Penthouses", 

      edition: "Silverglades Legacy", 

    }, 

    description: "Book your VC or Site Visit today!", 

    price: "5.88 Cr Onwards", 

    // button: "Book your VC Now", 

    rera: "RERA No. : RC/REP/HARERA/GGM/861/593/2024/88", 

  }, 

  //Price 

  price: { 

    heading: "Tentative Price", 

    tableHeading: ["Type", "Area (approx.)", "Price"], 

    plans: [ 

      { 

        type: "3 BHK", 

        sba: "2800 sq ft (approx.)", 

        price: "Rs. 5.88 Cr", 

        button: "Request For Details", 

      }, 

      { 

        type: "4 BHK + Utility", 

        sba: "3800 sq ft (approx.)", 

        price: "On request", 

        button: "Request For Details", 

      }, 

      { 

        type: "4 BHK + Utility + Study", 

        sba: "4800 sq ft (approx.)", 

        price: "On request", 

        button: "Request For Details", 

      }, 

    ], 

  }, 

  //Site & Floor Plan 

 

  siteAndFloorPlan: { 

    heading: "Site & Floor Plan", 

    popupData: { 

      heading: "Request for plan details", 

      button: "Request for plan details ", 

    }, 

 

    floorPlans: [ 

      { 

        heading: "Master Plan Plan", 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%20masterplan.webp6169ae25-fba7-4506-bb6b-60c3f752f1d3?alt=media&token=033525e7-2dc4-4e9a-bc99-09218c0a4da6", 

        button: "Master Plan", 

        hoverButton: "Enquire Now", 

      }, 

      { 

        heading: "Floor Plan", 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%203bhk.webp115ffd49-7f00-4444-a0c8-3724b19ebeaf?alt=media&token=6fe2e38d-847c-467b-ba88-16cef4887b52", 

        button: "3 BHK", 

        hoverButton: "Enquire Now", 

      }, 

      { 

        heading: "Floor Plan", 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%204bhk.webp9d540eb0-a3d8-4672-85ea-2ddac6a14e76?alt=media&token=34ac24f0-d779-441a-8f1d-247a4894cda0", 

        button: "4 BHK + Utility", 

        hoverButton: "Enquire Now", 

      }, 

      { 

        heading: "Floor Plan", 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%205bhk.webp0298bfc5-2197-467f-9bf5-0be013c87f0e?alt=media&token=54bfe497-62be-4939-a0f8-2fbb8bb43bae", 

        button: "4 BHK + Utility + Study", 

        hoverButton: "Enquire Now", 

      }, 

    ], 

  }, 

 

  //AMENITIES 

 

  amenities: { 

    heading: "Amenities", 

    image: false, 

    images: [ 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3", 

        bottomSideHeading: "AMPHITHEATRE", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a", 

        bottomSideHeading: "BASKETBALL COURT", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639", 

        bottomSideHeading: "CRICKET PRACTICE NET", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396", 

        bottomSideHeading: "TABLE TENNIS", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a", 

        bottomSideHeading: "JOGGING TRACK", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3", 

        bottomSideHeading: "GYM", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01", 

        bottomSideHeading: "REFLEXOLOGY PATHWAY", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb", 

        bottomSideHeading: "SWIMMING POOL", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773", 

        bottomSideHeading: "KIDS PLAY AREA", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db", 

        bottomSideHeading: "CYCLING TRACK", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb", 

        bottomSideHeading: "YOGA LAWN", 

      }, 

    ], 

    icons: [ 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3", 

        bottomSideHeading: "AMPHITHEATRE", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a", 

        bottomSideHeading: "BASKETBALL COURT", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639", 

        bottomSideHeading: "CRICKET PRACTICE NET", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396", 

        bottomSideHeading: "TABLE TENNIS", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a", 

        bottomSideHeading: "JOGGING TRACK", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3", 

        bottomSideHeading: "GYM", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01", 

        bottomSideHeading: "REFLEXOLOGY PATHWAY", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb", 

        bottomSideHeading: "SWIMMING POOL", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773", 

        bottomSideHeading: "KIDS PLAY AREA", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db", 

        bottomSideHeading: "CYCLING TRACK", 

      }, 

      { 

        imageLink: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb", 

        bottomSideHeading: "YOGA LAWN", 

      }, 

    ], 

  }, 

  //Gallery 

 

  gallery: { 

    heading: "Gallery", 

    images: [ 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%201.webpfcf73059-d72b-4783-b926-0799a0797067?alt=media&token=c9a5ec5c-6653-449f-aacb-0478790e9d24", 

        bottomSideHeading: "Artistic Impression", 

      }, 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%202.webp15547762-eb07-4ef7-bd5e-6685df34e5b3?alt=media&token=804c2345-53d6-423b-9692-174f8c63a608", 

        bottomSideHeading: "Artistic Impression", 

      }, 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%203.webp492fa834-1a6b-4bb9-9ee6-0e02d81ab74a?alt=media&token=9e4753ea-ed86-43a0-b1e9-91b5ed617b77", 

        bottomSideHeading: "Artistic Impression", 

      }, 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%205.webpf002abae-0fc7-4fe4-bf20-96ebd04d73fb?alt=media&token=c3b74a3a-0dd6-496c-9047-0329a295091f", 

        bottomSideHeading: "Artistic Impression", 

      }, 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%204.webp3c7a60b8-2d88-4164-8b94-0d3fd72ad45a?alt=media&token=5a4dd735-eced-42d2-839c-cb2868d8e9b6", 

        bottomSideHeading: "Artistic Impression", 

      }, 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%206.webp929f8123-bc18-4ea5-877b-f5e74e27ea53?alt=media&token=da5832e2-21a5-4eb0-ba8d-1da334385097", 

        bottomSideHeading: "Artistic Impression", 

      }, 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%207.webpfcbca690-ee61-497c-b647-b3b3782cbd01?alt=media&token=ed190368-cd7f-4e16-958c-8864eb0bd2ce", 

        bottomSideHeading: "Artistic Impression", 

      }, 

      { 

        imageLink: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20gallery%208.webp1925ff2c-5cd2-443f-8d7f-52e0d87d2625?alt=media&token=8c6e6fe4-23cd-4d7b-b709-80870c1a2db6", 

        bottomSideHeading: "Artistic Impression", 

      }, 

    ], 

  }, 

 

  //LOCATION 

 

  location: { 

    heading: "Location", 

    mapView: { 

      heading: "Map View", 

      mapLink: 

        "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3143.7637443138938!2d77.115916!3d28.409670000000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI0JzM0LjgiTiA3N8KwMDYnNTcuMyJF!5e1!3m2!1sen!2sin!4v1724832522306!5m2!1sen!2sin", 

      button: "Know More About Location", 

      popupData: { 

        heading: "Explore Location", 

        button: "Request Location", 

      }, 

    }, 

    locationMap: { 

      heading: "Location Map", 

      imageLink: 

        "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20location.webp5c130e24-5dc0-4737-9ae5-3708132ffa12?alt=media&token=18476363-03ea-423e-bdb7-5508e5784286", 

      button: "View Location Map", 

      popupData: { 

        heading: "Explore Location", 

        button: "Request Location", 

      }, 

    }, 

  }, 

  //Virutal  Taur 

  virtualSiteTour: { 

    heading: "Virtual Tour", 

    items: [ 

      { 

        heading: "VIRTUAL SITE TOUR", 

        subHeading: "Silverglades The Legacy at Sector 63A, Gurgaon", 

        bottomSideHeading: "Artistic Impression", 

        topSideHeading: "", 

        backgroundImage: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20walkthrough.webp4aff9b2b-0344-45ce-bdec-a7a674fb9d27?alt=media&token=cff1471e-bd42-4551-bc13-a6fefb5912b9", 

        popupData: { 

          heading: "Request For Virtual Site Tour", 

          button: "Virtual Site Tour", 

        }, 

      }, 

      { 

        heading: "SAMPLE FLAT", 

        subHeading: "Silverglades The Legacy at Sector 63A, Gurgaon", 

        bottomSideHeading: "Artistic Impression", 

        // topSideHeading: "Unleash Your Golfing Skills on Breathtaking 9-Hole Chip & Golf Greens", 

        backgroundImage: 

          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Flegacy%20sample%20apt.webp559d0724-7fe1-4dd1-9c12-09e034511f0e?alt=media&token=1730c7b7-2dd3-4c47-9b0e-242be3ffd8cd", 

        popupData: { 

          heading: "Request For Virtual Site Tour", 

          button: "Virtual Site Tour", 

        }, 

      }, 

    ], 

  }, 

  //about the developer 

  aboutthedeveloper: { 

    heading: "About The Developer", 

    para: "Silverglades is a prominent boutique real estate developer in India, renowned for its expertise in residential, commercial, township, and golf-based leisure projects. Under the visionary leadership of Mr. Pradeep Jain, Silverglades has developed several prestigious projects that have set benchmarks in the industry. In collaboration with ITC Limited, Silverglades delivered two landmark projects in Gurgaon: The Laburnum, an upscale residential development, and the Classic Golf Resort, India's first private golf course. Building on this success, the company launched The IVY, a luxury housing project in Gurgaon, and Tarudhan Valley Golf Resort, India's first gated golf community, located just a kilometer from the Classic Golf Resort.<br></br> Through its innovative approach and commitment to quality, Silverglades has been instrumental in shaping the real estate landscape in Delhi NCR, creating some of the region's most iconic landmarks. Their pioneering developments continue to reflect their dedication to delivering exceptional lifestyle experiences.", 

  }, 

 

  footer: { 

    topLine: "Channel Partner RERA No. : RC/REA/HARERA/GGM/1327/922/2O2O/26", 

    bottomLine: "Project RERA No. : RC/REP/HARERA/GGM/861/593/2024/88", 

    link: "https://haryanarera.gov.in/", 

    disclimer: 

      "This is not the official website of the developer & property, it belongs to an authorised channel partner for information purposes only. All rights for logos & images are reserved to the developer. Thank you for visiting our website. This disclaimer Disclaimer is applicable to this website and all microsites and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation. This website is in the process of being updated. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website are solely for informational purposes only and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website, constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/information on this website.", 

    footerAllRight: 

      "All Rights Reserved. © 2024 Copyright PropCrown Global LLP", 

  }, 

 

  details: { 

    whatsappNumber: "+919990887599", 

    phoneNumber: "+919990887599", 

    whatsappMessage: 

      "Hi, I am interested in Silverglades The Legacy. Please share the details with me.", 

  }, 

  formData: {
    placeHolder: "Please suggest a time (in IST) to get in touch.",
    phonePlaceholder : "Phone number with country code",
    popup: {
      rows: 3,
    },
    sideFormRows: 2,
  },

  //thank you form 

  thankYouPage: { 

    heading: "Thank You", 

    thankyouText: "for filling the form", 

    image: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FLegacy%20thank%20you.webp2dbd5bb1-2273-425a-b913-a455f7a26645?alt=media&token=7bc79689-a1a2-4efa-9ae5-be7243ae84c8", 

    appreciateLines: { 

      lineOne: "We appreciate your interest", 

      lineTwo: "and will get back to you shortly.", 

    }, 

  }, 

  disclaimer: ` 

  <section> 

    <h2>Disclaimer</h2> 

    <p> 

      This website is only for the purpose of providing information regarding real estate projects in different regions. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website is solely for informational purposes and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/ information on this website. 

    </p> 

    <p> 

      Please also note that the company has not verified the information and the compliances of the projects. Further, the company has not checked the RERA (Real Estate Regulation Act 2016) registration status of the real estate projects listed herein. The company does not make any representation in regards to the compliances done against these projects. You should make yourself aware of the RERA registration status of the listed real estate projects before purchasing property. 

    </p> 

    <p> 

      This site is for information purposes only and should not be treated as the official website. 

    </p> 

  </section> 

 

  <section> 

    <h2>Privacy Policy</h2> 

    <h3>Updation of privacy policy</h3> 

    <p> 

      This privacy policy is subject to undergo change and review without any prior notice or approval. So to keep yourself updated on the changes introduced, please keep visiting and reviewing the terms and conditions of this privacy policy. 

    </p> 

 

    <h3>User information</h3> 

    <p> 

      By using our website, you agree to abide by the rules laid out by us and consent to the collection and use of all such information that you may furnish to, or through, our website. In some cases, while you visit our website, you may not need to provide any personal information. But in certain instances, we must have your personal information in order for us to grant you access to some of the links or sites. Such links/ pages may ask for your name, e-mail address, phone number, etc. The information furnished by you is used to provide relevant products and services and to acknowledge receipt of your communication or to send out information and updates to you. You have the option of requesting removal from our mailing list. We do not give away your personal information to any third party. 

    </p> 

 

    <h3>Security</h3> 

    <p> 

      To ensure security while transferring sensitive information, all the ongoing transmissions between client and server are encrypted using advanced and standard protocols. We also practice restricted access by employees and hold them to high levels of confidentiality. 

    </p> 

 

    <h3>Use of cookies</h3> 

    <p> 

      We may use cookies for security, session continuity, and customization purposes. In case of a user opting to reject a cookie, he/ she may not be able to gain access to some of the limited services or use some features of the site. 

    </p> 

 

    <p> 

      In case of any queries or suggestions regarding the privacy statement or your dealings with this website, please contact: 

    </p> 

  </section> 

`, 

  popup: { 

    leftSide: { 

      heading: "We Promise", 

      iconsImages: [ 

        { 

          label: "Instant Call Back", 

          imageLink: 

            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fcall-back-icon-16.jpgfeb2b5f4-9b58-4eae-87e9-c8aca81d5e45?alt=media&token=bb54e66f-5637-4ca0-9f40-2008ee8b2802", 

        }, 

        { 

          label: "Project Video Conferencing", 

          imageLink: 

            "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FVC.svgcb712f12-aac9-492a-a2f7-74c63a3918ea?alt=media&token=3848f904-807c-4e6c-9751-832759017e10", 

        }, 

        { 

          label: "Unmatched Price", 

          imageLink: 

            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fbest-deal-badge-icon-best-deal-banners-badge-sticker-sign-tag-best-offer-modern-style-illustration-vector.jpg2c5a7144-c7c9-4913-b229-5280ed00fdc4?alt=media&token=ddf9f9c3-d403-4961-9557-850792a7b112", 

        }, 

      ], 

    }, 

    rightSide: { 

      heading: "Project Highlights", 

      points: [ 

        "RERA Received Now", 

        "Excellent Connectivity", 

        "Luxurious Living", 

        "Preference for NRIs", 

      ], 

    }, 

  }, 

  sideForm: { 

    topBarLeft: { 

      label: "Organise Site Visit", 

      popupData: { 

        heading: "Request For Virtual Site Tour", 

        button: "Virtual Site Tour", 

      }, 

      topBarRight: { 

        label: "Whatsapp", 

        imageLink : "" 

      } 

 

    }, 

    buttonAfterCallButton: { 

      label: "Request for Call Back", 

      popupData: { 

        heading: "Request For Call Back", 

        button: "Request For Call Now ", 

      }, 

    }, 

  }, 

  backendUrl: "https://landing-pages-backend.onrender.com", 

}; 
 

//image ratio
 

// hero = >  

//   desktop : 1183*730 

//   mobile : 390*400 

//logo =>92*54 

//price => 420*227 

// floorPlan => 425*319 

//amenites => square 

//gallery => square  

//location =>687*310 

//video => 569*323 
