import React from 'react'
import image from '../../../assets/logo.png'
import { data } from '../../../config/data'

const Logo = () => {
  return (
    <div
      
      style={{
        display: 'flex',
        justifyContent: "center",
        alignItems : "center"
      }}
    >
      <img
        alt ="Logo"
        
        style={{
          width: "120px"
          
        }}
        loading="lazy"
        
        src={data?.logo} />
    </div>
  )
}

export default Logo
